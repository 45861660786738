* {
  .react-datepicker-wrapper {
    input {
      height: 66px;
      border: 0px;
      border-radius: 30px;
    }
  }

  /* --rentujemy css variables defined in main index.html */
  input#prolongation-checkout {
    height: 41px;
    padding-left: var(--amplify-components-fieldcontrol-padding-inline-start);
    width: 100%;
    border: solid 1px var(--amplify-components-fieldcontrol-border-color);
    border-radius: var(--amplify-components-fieldcontrol-border-radius);
  }

  .react-datepicker__month-container {
    width: 100%;
  }

  .react-datepicker__header {
    background-color: var(--rentujemy-background);
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    width: 2rem;
    line-height: 2rem;
    margin: 0.2rem;
  }

  .react-datepicker__day--in-selecting-range {
    background-color: var(--rentujemy-primary);
  }

  .react-datepicker__day--keyboard-selected {
    color: var(--rentujemy-background);
  }

  .react-datepicker__day--in-range {
    background-color: var(--rentujemy-primary);
  }

  .react-datepicker__day--selected {
    background-color: var(--rentujemy-primary);
  }

  .react-picker-day:focus {
    background-color: var(--rentujemy-primary);
  }
}
