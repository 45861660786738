$theme-colors: (
  "primary": #213458,
);

@import "../node_modules/bootstrap/scss/bootstrap";

[data-amplify-theme] {
  --amplify-components-heading-1-font-size: 2.5rem;
  --amplify-components-heading-1-font-weight: 500;

  --amplify-components-heading-2-font-size: 2rem;
  --amplify-components-heading-2-font-weight: 500;

  --amplify-components-heading-3-font-size: 1.5rem;
  --amplify-components-heading-3-font-weight: 500;
  --amplify-colors-text-primary: map-get($theme-colors, "primary");
  --amplify-components-button-primary-background-color: map-get(
    $theme-colors,
    "primary"
  );
  --amplify-components-checkbox-icon-background-color: map-get(
    $theme-colors,
    "primary"
  );
}

* {
  --bs-dropdown-item-padding-y: 10px;
}

// // for fixing checkboxes
.amplify-checkboxfield {
  img,
  svg {
    vertical-align: unset;
  }
}

.container {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 100px);
  padding: 0 15px 15px 15px;
  max-width: 1000px;
  flex: 1;
}

/* Prevent scrolling on html and body on mobile devices */
@media (max-width: 768px) {
  html,
  body {
    height: 100%;
    overflow: hidden;
  }

  .container {
    height: 0px;
    overflow: scroll;
  }
}
